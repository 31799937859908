import React, { useEffect, useState } from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import SearchComponent from './SeachFieldForm/SearchFieldForm';
import css from './SectionHero.module.css';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { useConfiguration } from '../../../../context/configurationContext';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withViewport } from '../../../../util/uiHelpers';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../../util/search';
import moment from 'moment';
import { createResourceLocatorString } from '../../../../util/routes';
import { GARDEN } from '../../../../util/types';
import TextAnimate from './TextAnimate/TextAnimate';
import IconCard from '../../../../components/SavedCardDetails/IconCard/IconCard';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    sectionName,
  } = props;


  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const [tab, setTab] = useState("pool");
  const [showGarden, setShowGarden] = useState(false);
  const toRotate = ["una piscina", "un jardín"];
  const period = 2000;

  const handleTextChange = (newText) => {
    setShowGarden(newText == "un jardín");
  };

  const handleSeachSubmit = (values) => {
    const { location, bookingDate = {}, totalPersons, startHours, endHours, keywords } = values || {};
    const { currentSearchParams, history } = props;
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};
      const dates = bookingDate?.date ? moment(bookingDate?.date).format("YYYY-MM-DD") : null;
      // const guestCount = totalPersons?.split("-")?.length > 1 ? totalPersons?.split("-")[1] : totalPersons?.split("-")[0];
      // const guestCount = totalPersons.map(item => item.split("-").length > 1 ? item.split("-")[1] : item.split("-")[0]);
      const guestCount = totalPersons;

      const searchObj = {
        ...originMaybe,
        address: search,
        bounds,
        // pub_capacity: guestCount,
        // time: `${startHours}, ${endHours}`,
        // dates: `${dates},${dates}`
      };
      if (guestCount) {
        Object.assign(searchObj, { pub_capacity: guestCount });
      }
      if (startHours && endHours) {
        Object.assign(searchObj, { time: `${startHours}, ${endHours}` });
      }
      if (dates) {
        Object.assign(searchObj, { dates: `${dates},${dates}` });
      }
      return searchObj;
    };

    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    // sessionStorage.setItem('SearchListing', JSON.stringify(tab));
    if (tab == GARDEN) {
      return history.push(createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: "jardines" }, searchParams));
    }
    else {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));

    }
  };

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const handletab = (activeTab) => {
    setTab(activeTab);
  };
  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      heroAppearance={tab === GARDEN}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={sectionId === "hero" ? css.heroWrapper : defaultClasses.sectionDetails}>
          {sectionId === "hero" ?
            <div className={css.heroSwiper}>
              <div>
                <FormattedMessage id="SectionHero.heading.start" />
                <TextAnimate
                  toRotate={toRotate}
                  onTextChange={handleTextChange}
                  period={period} />
                <div className={css.normalText}>
                  {showGarden ? "privado " : "privada "}
                  <FormattedMessage id="SectionHero.heading.end" />

                </div>
              </div>

            </div>
            :
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          }
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {sectionId != "sube-tu-piscina" ? <div className={css.buttonWrapper}>
        <button className={tab == "pool" ? css.activeButton : css.inactiveButton} onClick={() => handletab("pool")}>
          <FormattedMessage id="SectionHero.SearchListingHeading.pool" />
        </button>
        <button className={tab == GARDEN ? css.activeButton : css.inactiveButton} onClick={() => handletab("garden")}>
          <FormattedMessage id="SectionHero.SearchListingHeading.garden" />
        </button>
      </div> : null}
      {/* Search Fields */}
      {tab === "pool" ?
        <div>
          {sectionName === "landing-hero" ?
            <SearchComponent
              onSubmit={(values) => handleSeachSubmit(values)}
              intl={intl}
            />
            : null}
        </div>
        :
        <div>
          {sectionName === "landing-hero" ?
            <SearchComponent
              onSubmit={(values) => handleSeachSubmit(values)}
              intl={intl}
            />
            : null}
        </div>}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default compose(injectIntl, withRouter, withViewport)(SectionHero);
